<script>

    import typical from 'vue-typical'
    import NavLanding from "../../components/structure/nav-landing";
    import FooterLanding from "../../components/structure/footer-landing";
    export default {
        components: {FooterLanding, NavLanding, typical},
        name:'landingBusiness',
        mounted () {
            window.scrollTo(0, 0)
        }

    };
</script>

<template>
    <div>
        <nav-landing/>
        <div v-scroll-spy>
            <section class=" py-landing sec-img sec-img2 text-center">
              <div class="back-content py-landing">
                    <div class="row m-0 justify-content-center">
                    <div class="col-12 col-md-10 col-lg-9">
                        <typical
                                class="typicalWrapper text-white font-weight-bold mb-3 mt-5"
                                :steps="[$t('landing.nav.business')+' - ', 500,$t('landing.business.section1.title'), 1000]"
                                :loop="Infinity"
                                :wrapper="'h1'"
                        ></typical>
                        <p class="font-size-18 my-4 text-muted">
                            {{$t('landing.business.section1.desc')}}
                        </p>

                        <a class="btn btn-fill login-btn w-xs" @click="$router.push({ name: 'Register' })">{{$t('landing.nav.join')}}</a>


                    </div>
                </div>
                </div>
            </section>
            <section class="section py-4 bg-white" id="why">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-8 m-auto">
                            <h1 class="text-center my-3">{{$t('landing.business.section2.title')}}</h1>
                            <p class="font-size-16 mb-5">{{$t('landing.business.section2.p')}}</p>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="card border">
                                        <div class="card-body text-center">
                                            <img    width="80" alt
                                                     src="@/assets/images/icon/net.png"
                                                     class="img-fluid  d-block m-auto" />
                                                    <h4 class="my-3">{{$t('landing.business.section2.t1')}}</h4>
                                                    <p class="text-muted">{{$t('landing.business.section2.p1')}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="card border">
                                        <div class="card-body text-center">
                                                    <img     width="80" alt
                                                             src="@/assets/images/icon/data-security.png"
                                                             class="img-fluid m-auto d-block"/>
                                                    <h4 class="my-3">{{$t('landing.business.section2.t2')}}</h4>
                                                    <p class="text-muted">{{$t('landing.business.section2.p2')}}</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </section>

            <section class="section  py-4 bg-color-blue-nav" id="faqs">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <h1 class="text-center my-5">{{$t('landing.other')}} </h1>
                        </div>
                    </div>
                    <!-- end row -->
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="vertical-nav">
                                <b-tabs pills justified>
                                    <b-tab active>
                                        <template v-slot:title>
                                            <i class="bx bx-help-circle nav-icon d-block mb-2"></i>
                                            <p class="font-weight-bold mb-0">{{$t('landing.particular.section9.ctf.title')}}</p>
                                        </template>
                                        <b-card-text>
                                            <div class="card">
                                                <div class="card-body">
                                                  <div class="row justify-content-center">
                                                    <div class="col-xl-4">
                                                      <img src="@/assets/images/crisis/CrisisCTF.png"
                                                           alt class="img-fluid "/>
                                                    </div>
                                                    <div class="col-xl-5 my-auto">
                                                      <div class="font-size-16">
                                                        <p>{{$t('landing.business.section3.ctf.p1')}}</p>
                                                        <p>{{$t('landing.business.section3.ctf.p2')}}</p>
                                                        <p>{{$t('landing.business.section3.ctf.p3')}}</p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                            </div>
                                        </b-card-text>
                                    </b-tab>
                                    <b-tab>
                                        <template v-slot:title>
                                            <i class="bx bx-receipt nav-icon d-block mb-2"></i>
                                            <p class="font-weight-bold mb-0">{{$t('landing.particular.section9.training.title')}}</p>
                                        </template>
                                        <b-card-text>
                                            <div class="card">
                                                <div class="card-body">
                                                  <div class="row justify-content-center">
                                                    <div class="col-xl-4">
                                                      <img src="@/assets/images/crisis/CrisisTraining.png"
                                                           alt class="img-fluid "/>
                                                    </div>
                                                    <div class="col-xl-5 my-auto">
                                                      <div class="font-size-16">
                                                        <p>{{$t('landing.business.section3.training.p1')}} </p>
                                                        <p>{{$t('landing.business.section3.training.p2')}} </p>
                                                      </div>
                                                    </div>
                                                  </div>

                                                </div>
                                            </div>
                                        </b-card-text>
                                    </b-tab>
                                    <b-tab>
                                        <template v-slot:title>
                                            <i class="bx bx-timer d-block nav-icon mb-2"></i>
                                            <p class="font-weight-bold mb-0">{{$t('landing.particular.section9.careers.title')}}</p>
                                        </template>
                                        <b-card-text>
                                            <div class="card">
                                                <div class="card-body">
                                                  <div class="row justify-content-center">
                                                    <div class="col-xl-4">
                                                      <img src="@/assets/images/crisis/CrisisCareer.png"
                                                           alt class="img-fluid "/>
                                                    </div>
                                                    <div class="col-xl-5 my-auto">
                                                      <div class="font-size-16">
                                                        <p>{{$t('landing.business.section3.careers.p1')}}</p>
                                                        <p>{{$t('landing.business.section3.careers.p2')}}</p>
                                                        <p>{{$t('landing.business.section3.careers.p3')}}</p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                            </div>
                                        </b-card-text>
                                    </b-tab>
                                </b-tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class=" bg-color-blue-nav" >
                <div class="container">
                    <div class="row">
                        <div class="col-xl-8 m-auto">
                            <h1 class="text-center my-5">{{$t('landing.business.section4.t')}}</h1>
                            <p class="font-size-16 mb-5">{{$t('landing.business.section4.p')}}</p>
                            <h4 class="text-center text-primary mb-4">{{$t('privacy.contact_us')}} </h4>
                        </div>

                    </div>
                </div>
            </section>

            <!-- Footer start -->
           <footer-landing/>
            <!-- Footer end -->
        </div>
    </div>
</template>
<style scoped src="@/assets/styles/custom/structure/landing.css"></style>
<style scoped>
</style>