import { render, staticRenderFns } from "./landingBusiness.vue?vue&type=template&id=648fe938&scoped=true"
import script from "./landingBusiness.vue?vue&type=script&lang=js"
export * from "./landingBusiness.vue?vue&type=script&lang=js"
import style0 from "@/assets/styles/custom/structure/landing.css?vue&type=style&index=0&id=648fe938&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "648fe938",
  null
  
)

export default component.exports